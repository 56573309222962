// Fonts
$font-path: '/styles/fonts';
//@import url('https://fonts.googleapis.com/css?family=Merriweather:300,300i,400,400i,700,700i|Open+Sans:300,400,400i,600,600i,700,700i&subset=cyrillic,cyrillic-ext,latin-ext');
$font-family-sans-serif: 'Open Sans', Roboto, 'Helvetica Neue', Arial,
  sans-serif;
$font-family-serif: 'merriweather', 'Times New Roman', Times, serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 14px;
$font-size-h1: 34px;
$font-size-h2: 20px;
$font-size-h3: 19px;
// Forms
$input-border-focus: #7abaea;
//dimension
$hederHeightTop: 102px;
$headerInfoBar: 40px;
$heightFooterHeader: 150px;
$heightMobileFooterHeader: 122px;
$navPaddingX: 5vw;
$navFixedPaddingX: 7.5vw;

$buttonTransition: all 0.3s;

/// NEW NEXT JS VARIABLES !!!

$grid-breakpoints: (
  xs: 380px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
);

// Colors

$mainColor: #404042;
$mainColorLighten: #4a4a4d; //rgb(58,57,60);
$mainColorGlass: rgba(115, 115, 115, 0.9);

$white: rgba(255, 255, 255);
$whiteHover: darken($white, 8);

$lightGray: #e7e8ee;

$textDark: #2a2b34;
$textColor: #d14900;
$promoBorderColor: #f1874e;

$tagColor: #3c82d6;

$oldBrandCol2: #f87251;
$oldBrandColor3: #36506c;
// SOCIALS
$fb: #3b5998;
$tt: #55acee;
$li: #007bb5;
$wykop: #367da9;
$oneLogin: #00548b;
$google: #dc3545;
$xing: #126567;
$whatsapp: #25d366;
$messenger: #0084ff;
$viber: #59267c;
$telegram: #0088cc;
$vk: #45668e;
// Dimensions
$headerHeight: 110px;
$headerInfoBarHeight: 48px;
$headerHeightMobile: 60px;

// > DARK MODE
$darkModeBackground: #021e2f;
$darkModeLightBackground: #20405f;
$darkModeBackgroundGray: #c3c6c9;
$darkModeOrange: #f08559;
$darkModeGray: #c3c7ca;
$darkModeDarkGray: #737373;
$darkModeBlue: #395778;
$darkModeText: white;
$darkModeTextGray: #e2e2e2;
$darkModeInputBackground: #aeb5bd;

$newMenuLightGray: #d9d9d9;
$newMenuGray: #646464;
$newMenuDarkGray: #949494;

// > PREMIUM
$premium900: #015c92;
$premium700: #2d82b5;
$premium500: #53a7d8;
$premium200: #83c2fe;
$premium100: #bbdeff;
$orange900: #ff7c02;
$orange700: #f18200;
$orange500: #f88d2a;
$orange200: #ffa959;
$orange100: #fddbbc;
$premiumDark: #002d48;
$premiumOrange: #ffa959;
$almostWhite: #dcdcdc;
$greytxt: #646464;
$lightGrey: #f3f3f3;
$errorRed: #ff0033;
$christmasRed: #c22421;
$golden: #fcdc8f;

// > GRADIENTS
$goldenGradient: linear-gradient(92deg,
    #fcdc8f 11.09%,
    #fce6ad 48.53%,
    #fcdc8f 84.88%);
$blackGradient: linear-gradient(298deg, #191f25 23.56%, #0b0e10 79.17%);
//- $premium900: #054D49;
//- $premium700: #088079;
//- $premium500: #0A9D95;
//- $premium200: #0ED9CE;
//- $premium100: #10FAED;