@import 'styles/variables';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  padding-left: 24px;
}
.person {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  :global(.thumbnail) {
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    :global(.thumbnail) {
      transform: scale(1.05) translateY(-1px);
    }
  }
  .info {
    display: flex;
    width: min-content;
    cursor: pointer;
    margin-left: 0.5rem;

    &.col {
      flex-direction: column;
      .name,
      .caption {
        margin-bottom: 0px;
        word-break: none;
        white-space: nowrap;
      }
    }
    &.row {
      flex-direction: row;
      .name,
      .caption {
        margin-left: 20px;
        margin-bottom: 0;
        word-break: none;
        white-space: nowrap;
      }
    }
  }
  &.colorscheme {
    &-main {
      .name {
        color: $premium100;
      }
      .caption {
        color: $almostWhite;
      }
    }
    &-article {
      .name {
        color: $premium900;
        font-weight: 300;
      }
      .caption {
        color: $greytxt;
        font-weight: 300;
        border-width: 1px !important;
      }
    }
    &-standard {
      .name {
        color: $premium900;
      }
      .caption {
        color: $greytxt;
      }
    }
    &-recommended {
      a {
        width: 100%;
      }
      .name {
        color: $premium900;
        white-space: break-spaces;
      }
      .caption {
        color: $greytxt;
        white-space: break-spaces;
      }
      .author-info {
        flex-grow: 1;
      }
      :global(.thumbnail.premium) {
        width: 48px !important;
        height: 48px !important;
        min-width: 48px !important;
      }
    }
    &-video {
      .name {
        color: $orange500;
      }
      .caption {
        color: $white;
        font-weight: 100;
      }
      .divider {
        border-color: $orange500;
      }
      @media (max-width: map-get($grid-breakpoints, 'sm')) {
        .line {
          column-gap: 4px !important;
        }
        .divider {
          height: auto;
        }
      }
    }
  }
}
.date {
  border-left: 2px solid;
  margin-left: 8px;
  padding-left: 8px;
  &.colorscheme {
    &-main {
      color: $almostWhite;
    }
    &-article {
      color: $greytxt;
      font-weight: 300;
      border-width: 1px !important;
    }
    &-standard {
      color: $greytxt;
    }
    &-recommended {
      color: $greytxt;
      white-space: break-spaces;
    }
    &-video {
      color: $white;
      opacity: 0.8;
      font-weight: 700;
    }
  }
}

@media (max-width: map-get($map: $grid-breakpoints, $key: md )) {
  .wrapper {
    position: relative;
  }
  .person {
    column-gap: 10px;
    .info .caption {
      width: max-content;
    }
  }
  .date {
    position: absolute;
    right: 10px;
    bottom: 10.5px;
    height: min-content !important;
  }
}

@media (max-width: map-get($map: $grid-breakpoints, $key: sm )) {
  :global(.article-cover) {
    .wrapper {
      height: 80px;
      background: #fff;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e7e8ee;
    }
  }
  .wrapper {
    flex-wrap: wrap;
    max-width: 100%;
    a {
      display: inline-block;
      max-width: 100%;
    }
    .date {
      margin-right: 0px;
      margin-left: auto;
    }

    .info {
      max-width: calc(100% - 48px);
      width: 100%;
      .caption {
        word-break: keep-all;
        white-space: normal !important;
      }
    }
  }
  .person.colorscheme-main {
    .name,
    .caption {
      color: $premiumDark !important;
    }
  }

  .date {
    bottom: 50%;
    transform: translateY(50%);
    &.colorscheme-main {
      color: $premiumDark !important;
    }
  }
}

:global(.darkmode) {
  .person {
    &.colorscheme {
      &-main {
        .name {
          color: $orange200 !important;
        }
      }
      &-recommended {
        .name {
          color: $orange500 !important;
        }
        .caption,
        .date {
          color: white !important;
        }
      }
    }
  }
}

//author-intro-wrapper => .wrapper
//author-intro => .info
